import toast from "react-hot-toast";
import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import CommonButton from "../../Buttons/CommonButton";

const QuizTypeSelection = ({ isOpen, onClose }) => {
  return (
    <Modal
      focusTrapped={false}
      open={isOpen}
      onClose={onClose}
      showCloseIcon={false}
      classNames={{ modal: "thankyouModal" }}
      center
    >
      <div className="relative flex flex-col  items-center justify-center h-full  w-full">
        <div className="flex flex-col items-center justify-center">
          <h2 className="py-1.5  bg-[#760A98] text-white text-lg font-medium text-center w-full">
            Select Quiz Type
          </h2>

          <div className="border-b border-gray-500 mt-8 pb-8 px-3 gap-3 flex items-center justify-between">
            <Link
              // onClick={onClose}
              onClick={(e) => {
                e.preventDefault();
                toast.error("Not available yet");
              }}
              to={"/quizzes/practice"}
              className="font-semibold bg-orange-500 hover:bg-orange-600 cursor-pointer px-6 py-2 rounded-lg text-white border border-white border-4 shadow-lg"
            >
              Practice
            </Link>

            <Link
              onClick={onClose}
              to={"/quizzes/compete"}
              className="font-semibold bg-green-500 hover:bg-green-600 cursor-pointer px-6 py-2 rounded-lg text-white border border-white border-4 shadow-lg"
            >
              Compete
            </Link>
          </div>
        </div>

        <div className="my-4">
          <CommonButton
            classname={
              "py-2 border border-[#760A98] border-[3px] text-black font-bold hover:bg-red-400 hover:text-white"
            }
            text={"Close"}
            clickHandler={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};
export default QuizTypeSelection;
