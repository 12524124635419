import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

const uploadFileToDigitalOcean = async (file, path) => {
  const accessKeyId = process.env.REACT_APP_DO_SPACES_ACCESS_KEY;
  const secretAccessKey = process.env.REACT_APP_DO_SPACES_SECRET_KEY;

  if (!accessKeyId || !secretAccessKey) {
    throw new Error("Missing DigitalOcean Spaces credentials");
  }

  const client = new S3Client({
    region: "us-east-1",
    endpoint: "https://blr1.digitaloceanspaces.com",
    credentials: {
      accessKeyId,
      secretAccessKey,
    },
  });

  const command = new PutObjectCommand({
    Bucket: "cherriquiz",
    Key: `${path}/${file.name}`,
    Body: file,
    ACL: "public-read",
    ContentType: file.type,
  });

  try {
    const upload = client.send(command);
    await upload;

    return `https://blr1.digitaloceanspaces.com/${command.input.Bucket}/${command.input.Key}`;
  } catch (error) {
    console.error("Error uploading file:", error);
    return null;
  }
};

export default uploadFileToDigitalOcean;
