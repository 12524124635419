import React from "react";

const CommonButton = ({ text, classname, clickHandler }) => {
  return (
    <button
      type="button"
      onClick={clickHandler}
      className={`text-sm rounded-full px-6 outline-none w-fit ${classname}`}
    >
      {text}
    </button>
  );
};

export default CommonButton;
