import axios from "axios";
import toast from "react-hot-toast";
import { store } from "../redux/store";
import { setLogout } from "../redux/userSlice";
import { getRefreshToken, removeJwtTokens, setJwtTokens } from "../utils";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;
      try {
        const oldRefreshToken = getRefreshToken();
        if (oldRefreshToken) {
          const result = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/refresh-token`,
            { refreshToken: oldRefreshToken }
          );
          if (result.data.success) {
            const tokensData = result.data.data;
            setJwtTokens(tokensData);
            originalRequest.headers.authorization = `Bearer ${tokensData.accessToken}`;
            return axiosInstance(originalRequest);
          } else {
            throw new Error("Your session has expired. Please login again");
          }
        } else {
          throw new Error("Your session has expired. Please login again");
        }
      } catch (err) {
        removeJwtTokens();
        store.dispatch(setLogout({}));
        toast.error("Your session has expired. Please login again");
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
