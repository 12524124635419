
const TitleBetweenLine = ({ title, isImage }) => {
  return (
    <div className="relative w-full flex items-center ">
      <div className="mr-2 md:mr-6 flex-grow border-t border-gray-500"></div>
      {isImage ? (
        <img src={title} className="w-40 mx-auto" alt="prev-quizzes" />
      ) : (
        <h1 className="text-base md:text-xl text-[#760A98] font-medium">
          {title}
        </h1>
      )}

      <div className="ml-2 md:ml-6 flex-grow border-t border-gray-500"></div>
    </div>
  );
};

export default TitleBetweenLine;
