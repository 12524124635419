import { lazy, Suspense, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import axios from "./axios";
import PageLayout from "./components/Layout/PageLayout";
import MoonLoader from "./components/MoonLoader";
import ScrollToTop from "./components/ScrollToTop";
import { useGoogleAnalytics } from "./hooks";
import { selectUser, setLogout } from "./redux/userSlice";
import { getRefreshToken, removeJwtTokens, setJwtTokens } from "./utils";
import { subscribe } from "./utils/pushHelper";

const LazyMedia = lazy(() => import("./pages/Media"));
const LazyTitleLegend = lazy(() => import("./pages/TitleLegend"));
const LazyOTP = lazy(() => import("./pages/Login/Otp"));
const LazyHome = lazy(() => import("./pages/Home"));
const LazyUserInfo = lazy(() => import("./pages/Login/UserInfo"));
const LazyCompete = lazy(() => import("./pages/Quizzes/Compete"));
const LazyPractice = lazy(() => import("./pages/Quizzes/Practice"));

const LazyQuiz = lazy(() => import("./pages/Quiz"));
const LazyQuizInfo = lazy(() => import("./pages/QuizInfo"));
const LazyPerformance = lazy(() => import("./pages/Performance"));
const LazyWinners = lazy(() => import("./pages/QuizWinners"));
const LazyUserPublicProfile = lazy(() => import("./pages/UserPublicProfile"));
const LazyLeader = lazy(() => import("./pages/Leaderboards"));
const LazyHelp = lazy(() => import("./pages/Help"));
const LazyUserProfile = lazy(() => import("./pages/UserProfile"));
const LazyUserMathMasterProfile = lazy(() =>
  import("./pages/UserMathMasterProfile")
);
const LazyMathMaster = lazy(() => import("./pages/Games/MathMaster"));
const LazyShapeKing = lazy(() => import("./pages/Games/ShapeKing"));

const App = () => {
  useGoogleAnalytics();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [state, setState] = useState(user?.state || "");
  const [grade, setGrade] = useState(Number(user?.grade) || "");
  const [timespan, setTimespan] = useState("30");

  useEffect(() => {
    const registerServiceWorker = async () => {
      if ("serviceWorker" in navigator) {
        try {
          const reg = await navigator.serviceWorker.register(
            "/service-worker.js"
          );
          if (user?._id) {
            const subscription = await reg.pushManager.getSubscription();
            if (!subscription) {
              await subscribe(reg, user?._id);
            }
          }
        } catch (error) {}
      }
    };

    registerServiceWorker();
  }, [user?._id]);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      window.deferredPrompt = e;
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      console.log(originalRequest)
      if (error?.response?.status === 401 && !originalRequest?._retry) {
        originalRequest._retry = true;
        try {
          const oldRefreshToken = getRefreshToken();
          if (oldRefreshToken) {
            const result = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/api/user/refresh-token`,
              { refreshToken: oldRefreshToken }
            );
            if (result.data.success) {
              const tokensData = result.data.data;
              setJwtTokens(tokensData);
              originalRequest.headers.authorization = `Bearer ${tokensData.accessToken}`;
              return axios(originalRequest);
            } else {
              throw new Error("Your session has expired. Please login again");
            }
          } else {
            throw new Error("Your session has expired. Please login again");
          }
        } catch (err) {
          removeJwtTokens();
          dispatch(setLogout({}));
          navigate("/")
          toast.error("Your session has expired. Please login again");
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <ScrollToTop>
      <Suspense fallback={<MoonLoader />}>
        <Toaster />
        <PageLayout>
          <Routes>
            <Route path="/" element={<LazyHome />} />
            <Route
              path="/quizzes/compete"
              element={<PrivateRoute component={LazyCompete} />}
            />
            <Route
              path="/quizzes/practice"
              element={<PrivateRoute component={LazyPractice} />}
            />

            <Route path="/quizzes/:id" element={<LazyQuizInfo />} />
            <Route path="/quiz/:id" element={<LazyQuiz />} />
            <Route path="/quiz/:id/winners" element={<LazyWinners />} />
            <Route
              path="/user-profile"
              element={<PrivateRoute component={LazyUserProfile} />}
            />
            <Route
              path="/user-profile/:id"
              element={<LazyUserPublicProfile />}
            />
            <Route
              path="/user-mathmaster-profile/:id"
              element={<LazyUserMathMasterProfile />}
            />
            <Route path="/otp" element={<LazyOTP />} />

            <Route
              path="/titles"
              element={<PrivateRoute component={LazyTitleLegend} />}
            />
            <Route path="/newuserinfo" element={<LazyUserInfo />} />
            <Route
              path="games/maths/mathmaster/*"
              element={<PrivateRoute component={LazyMathMaster} />}
            />
            <Route
              path="games/general/shapeking/*"
              element={<PrivateRoute component={LazyShapeKing} />}
            />
            <Route
              path="/performance"
              element={<PrivateRoute component={LazyPerformance} />}
            />
            <Route
              path="/leaderboards"
              element={
                <LazyLeader
                  setGrade={setGrade}
                  setState={setState}
                  setTimespan={setTimespan}
                  state={state}
                  grade={grade}
                  timespan={timespan}
                />
              }
            />
            <Route path="/help" element={<LazyHelp />} />
            <Route path="/media" element={<LazyMedia />} />
          </Routes>
        </PageLayout>
      </Suspense>
    </ScrollToTop>
  );
};

export default App;
