import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MdLocalPhone, MdLock } from "react-icons/md";

const HtmlInput = ({
  type = "text",
  placeholder,
  name,
  id,
  onChange,
  onBlur,
  disabled,
  value,
  maxLength,
  onClick,
  pattern,
  onFocus,
  inputMode,
  handleKeyDown,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative w-full">
      {type === "phone" && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MdLocalPhone className="text-gray-700" />
        </div>
      )}

      {type === "password" && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MdLock className="text-gray-700" />
        </div>
      )}
      <input
        type={type === "password" && showPassword ? "text" : type}
        name={name}
        onClick={onClick}
        id={id}
        autoComplete="on"
        value={value}
        maxLength={maxLength || 100}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        className={`placeholder-gray-500 text-base border border-gray-400 rounded-md w-full outline-none py-[8px] px-[10px] ${
          type === "phone" || type === "password" ? "pl-10" : ""
        } `}
        placeholder={placeholder || ""}
        disabled={disabled}
        pattern={pattern}
        inputMode={inputMode}
        onKeyDown={handleKeyDown}
      />
      {type === "password" && (
        <button
          type="button"
          onClick={handleTogglePassword}
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-700"
        >
          {showPassword ? <FaEye /> : <FaEyeSlash />}
        </button>
      )}
    </div>
  );
};

export default HtmlInput;
