export default function formatTimeDifference(time, isSeconds = false) {
  const totalSeconds = isSeconds ? time : parseInt(time / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
}
