const installPWA = () => {
  const deferredPrompt = window.deferredPrompt;

  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
      } else {
        console.log("User dismiss ed the install prompt");
      }
      window.deferredPrompt = null;
    });
  }
};

export default installPWA;
