const setJwtTokens = ({ accessToken, refreshToken }) => {
  localStorage.setItem("CHERRIQUIZ_ACCESS_TOKEN", accessToken || "");
  localStorage.setItem("CHERRIQUIZ_REFRESHTOKEN", refreshToken || "");
};

const getJwtTokens = () => {
  const accessToken = localStorage.getItem("CHERRIQUIZ_ACCESS_TOKEN");
  const refreshToken = localStorage.getItem("CHERRIQUIZ_REFRESHTOKEN");
  return { accessToken, refreshToken };
};

const removeJwtTokens = () => {
  localStorage.removeItem("CHERRIQUIZ_ACCESS_TOKEN");
  localStorage.removeItem("CHERRIQUIZ_REFRESHTOKEN");
};

function getAccessToken() {
  return localStorage.getItem("CHERRIQUIZ_ACCESS_TOKEN") || "";
}

function getRefreshToken() {
  return localStorage.getItem("CHERRIQUIZ_REFRESHTOKEN") || "";
}

export {
  getAccessToken, getJwtTokens, getRefreshToken,
  removeJwtTokens, setJwtTokens
};
