import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  loginWithEmail,
  loginWithOtp,
  sendOtp,
  userCheckIfExists,
} from "../../apis/auth";
import cherriCup from "../../assets/images/cherriCup.png";
import chikkuMobile from "../../assets/images/chikkuWithMobile.png";
import gameIcon from "../../assets/images/games/mathmaster/gameIcon.svg";
import greenPlay from "../../assets/images/greenPlay.png";

import { HtmlInput } from "../../components/InputFields";
import TitleBetweenLine from "../../components/TitleBetweenLine";
import { selectUser, setUser } from "../../redux/userSlice";
import { setJwtTokens } from "../../utils";
import { useAuthContext } from "../Login/contexts/AuthContext";

const HomeLogin = ({
  inputFlexDir,
  setShowLoginModal,
  setShowQuizTypeModal,
  isHome,
}) => {
  
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { phone, setPhone } = useAuthContext();
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [phoneExists, setPhoneExists] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [otpSent, setOtpSent] = useState(false);
  const { otpSecret, setOtpSecret } = useAuthContext();

  const handlePhoneChange = (e) => {
    setPhone(e.target.value.trim());
    setPassword("");
    setPhoneExists(false);
  };

  const handleLoginWithPassword = async () => {
    const loginInfo = { email: phone, password };
    const response = await loginWithEmail(loginInfo);

    if (response?.data?.status === "success") {
      dispatch(setUser(response.data.profile));
      setJwtTokens(response.data.tokenData);
      if (setShowLoginModal) setShowLoginModal(false);
    } else {
      toast.error("Password Incorrect");
    }
  };

  const handleSendOtp = async () => {
    const response = await sendOtp({ phone, appSignature: "CHERRILEARN" });
    if (response.data.success) {
      setOtpSecret(response.data.otpSecret);
      setOtpSent(true);
    }
  };

  const handleResendOtp = async () => {
    if (resendDisabled)
      return toast.error(`Wait ${resendTimer} Seconds to Resend OTP!`);
    const response = await sendOtp({ phone, appSignature: "CHERRILEARN" });
    if (response.data.success) {
      setOtpSecret(response.data.otpSecret);
      setResendDisabled(true);
      setResendTimer(60);
      const interval = setInterval(() => {
        setResendTimer((prev) => {
          if (prev === 0) {
            setResendDisabled(false);
            clearInterval(interval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
  };

  const handleVerifyOtp = async () => {
    if (otp?.trim().length !== 6) return toast.error("Please Enter Valid OTP");
    const data = { otp, otpSecret, phone };
    const response = await loginWithOtp(data);
    if (response.data.status === "success") {
      dispatch(setUser(response.data.profile));
      setJwtTokens(response.data.tokenData);
      setOtpSecret("");
      if (setShowLoginModal) {
        setShowLoginModal(false);
      }
    } else {
      toast.error(response.data.message);
    }
  };

  const handlePhoneClick = async () => {
    if (phone.length !== 10) {
      return toast.error("Phone Number Must Be 10 Digits Long");
    }
    const response = await userCheckIfExists({
      phone,
      appSignature: "cherri-learn",
    });

    if (
      response.data.status === "success" &&
      response.data?.data?.studentExists
    ) {
      setPhoneExists(true);
    } else {
      setOtpSecret(response.data?.data.otpSecret);
      navigate("/otp");
      if (setShowLoginModal) setShowLoginModal(false);
    }
  };

  return (
    <>
      <div className="mt-2 h-[220px] md:h-[230px] w-[60%] md:w-auto">
        <img
          src={user?._id ? cherriCup : chikkuMobile}
          className="w-full h-full object-contain"
          alt="cherri-chikku"
        />
      </div>
      {!user?._id && !isHome && (
        <p className="text-2xl md:text-3xl text-center md:text-left font-medium">
          Signup and Start Playing!
        </p>
      )}
      {isHome ? (
        <div className="flex flex-col vsm:flex-row items-center justify-center gap-3">
          <Link
            onClick={(e) => {
              e.preventDefault();
              if (!user._id) setShowLoginModal(true);
              else navigate("/games/maths/mathmaster");
            }}
            to="/games/maths/mathmaster"
            className="bg-white flex justify-between gap-2 items-center font-medium outline-none px-2 py-1 border border-2 border-[#4EAD4F] w-fit"
          >
            <img className="h-10 animate-pulse-fast" src={gameIcon} alt="" />
            <p className="font-semibold text-md">Play Game</p>
          </Link>
          <Link
            onClick={(e) => {
              e.preventDefault();
              if (!user._id) setShowLoginModal(true);
              else setShowQuizTypeModal(true);
            }}
            to="/quizzes"
            className="bg-white flex justify-between gap-2 items-center font-medium outline-none px-2 py-1 border border-2 border-[#4EAD4F] w-fit"
          >
            <img className="h-10 animate-pulse-fast" src={greenPlay} alt="" />
            <p className="font-semibold text-md">Play Quiz</p>
          </Link>
        </div>
      ) : (
        <div
          className={`flex ${
            inputFlexDir === "col" ? "flex-col" : ""
          } items-start justify-between w-full max-w-md gap-4`}
        >
          <div className="flex-grow w-full">
            <HtmlInput
              placeholder="Phone No."
              value={phone}
              onChange={handlePhoneChange}
              type="phone"
              pattern="[0-9]*"
              inputMode="numeric"
              className="w-full"
            />
          </div>
          <button
            onClick={handlePhoneClick}
            className={`${
              password ? (inputFlexDir === "col" ? "hidden" : "invisible") : ""
            } bg-green-500 hover:bg-green-600 text-sm md:text-base font-medium outline-none  py-2.5 text-white rounded-full border-none w-36`}
          >
            Continue
          </button>
        </div>
      )}
      {phoneExists && !user?._id && (
        <>
          <div
            className={`flex ${
              inputFlexDir === "col" ? "flex-col" : ""
            } items-start justify-between w-full max-w-md gap-4`}
          >
            <div className="flex-grow w-full">
              <HtmlInput
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value.trim())}
                type="password"
                className="w-full"
              />
            </div>
            <button
              onClick={handleLoginWithPassword}
              className={`${
                password ? "" : inputFlexDir === "col" ? "hidden" : "invisible"
              } bg-green-500 hover:bg-green-600 text-sm md:text-base font-medium outline-none py-2.5 text-white rounded-full border-none w-36`}
            >
              Submit
            </button>
          </div>
          <TitleBetweenLine title={"OR"} />
          <div
            className={`flex ${
              inputFlexDir === "col" ? "flex-col" : ""
            } items-start justify-between w-full max-w-md gap-4`}
          >
            {otpSent && (
              <div className="flex-grow w-full ">
                <HtmlInput
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value.trim())}
                  type="text"
                  className="w-full"
                />
              </div>
            )}
            <button
              onClick={otpSent ? handleVerifyOtp : handleSendOtp}
              className={`text-sm md:text-base font-medium outline-none text-white rounded-full border-none  bg-orange-500 hover:bg-orange-600 ${
                otpSent ? "w-36 py-2.5" : "mx-auto w-fit px-8 py-3"
              }`}
            >
              {otpSent ? "Submit" : "Login via OTP"}
            </button>
          </div>
          {otpSent && (
            <p
              onClick={handleResendOtp}
              className={`-mt-2 font-medium underline underline-offset-2 text-md text-blue-600 cursor-pointer ${
                resendDisabled ? "text-gray-500" : ""
              }`}
            >
              {resendDisabled ? `Resend OTP in ${resendTimer}s` : "Resend OTP"}
            </p>
          )}
        </>
      )}
    </>
  );
};

export default HomeLogin;
