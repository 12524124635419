const convertNumbersArrayToRange = (numbers) => {
  if (numbers.length === 1) return String(numbers[0]);

  const lowest = Math.min(...numbers);
  const highest = Math.max(...numbers);

  return `${lowest} - ${highest}`;
};

export default convertNumbersArrayToRange;
