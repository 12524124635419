import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [studentName, setStudentName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [grade, setGrade] = useState("");
  const [otpSecret, setOtpSecret] = useState("");

  return (
    <AuthContext.Provider
      value={{
        studentName,
        setStudentName,
        phone,
        setPhone,
        password,
        setPassword,
        grade,
        setGrade,
        otpSecret,
        setOtpSecret,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
